// import React from "react";
// // import seoImage1 from "./assets/seoImage1.jpg";
// // import seoImage2 from "./assets/seoImage2.jpg";
// // import seoImage3 from "./assets/seoImage3.jpg";
// // import seoImage4 from "./assets/seoImage4.jpg";

// function BlogDetail() {
//   return (
//     // <div className="bg-gray-50 min-h-screen py-10 px-4 md:px-0">
//       <div className=" mx-auto bg-white rounded-lg shadow-md p-8 px-[20%] ">
        
//         {/* SEO-friendly Meta Tags */}
//         <meta name="description" content="Explore why virtual staging by Meryas Home is transforming real estate marketing, boosting online interest, and helping properties sell faster and for higher prices." />
//         <meta name="keywords" content="Virtual Staging, Real Estate Marketing, Meryas Home, Property Staging, Real Estate Canada" />
        
//         {/* Intro Section */}
//         <section className="mb-10">
//           {/* <p className="text-blue-600 font-semibold text-sm">Introduction</p> */}
//           <h1 className="text-4xl font-bold text-gray-800  mt-[14vh] mb-10 ">
//             Why Virtual Staging is a Game-Changer in Real Estate Marketing
//           </h1>
//           <p className="text-gray-600 leading-relaxed">
//             This post explores how virtual staging revolutionizes property marketing, helping listings stand out and increasing buyer engagement. It dives into how virtual staging addresses buyer expectations, boosts online interest, and ultimately speeds up sales.
//           </p>
//         </section>

//         {/* Image for Introduction */}
//         <div className="flex justify-center mb-8">
//           <img src="https://www.meryashome.com/static/media/MH_28.502521cdce6bd7110221.jpg" alt="Beautifully staged living room example" className="rounded-lg object-cover w-full h-64 md:h-80" />
//         </div>

//         {/* Content Sections */}
//         <section className="mb-10 space-y-8">
          
//           {/* Section 1 with Left Image */}
//           <h2 className="text-2xl font-semibold text-gray-800  !my-10">1. Meeting Modern Buyer Expectations</h2>
//           <p className="text-gray-600 leading-relaxed mt-2">
//                 Today’s buyers expect visually appealing listings that help them imagine a property as their future home. Meryas Home understands this need and uses virtual staging to create inviting, fully-furnished images that help buyers emotionally connect with spaces. Here’s why this matters:
//               </p>
//           <div className="flex flex-col md:flex-row items-center gap-8">
            
//             <div className="flex-1">
             
//               <ul className=" list-inside ml-4 mt-4 space-y-2 text-gray-600 list-none">
//                 <li className="leading-relaxed mt-2 text-lg"><strong>Visualization:</strong> Blank spaces can feel cold or hard to imagine as a home. Virtual staging by Meryas Home creates a warm, inviting look that enables buyers to envision how a room can be used.</li>
//                 <li className="leading-relaxed mt-2 text-lg"><strong>Personalization:</strong> Meryas Home customizes staging to appeal to specific buyer demographics, whether it’s a young family, a professional couple, or retirees. This tailored approach makes listings more relatable and appealing.</li>
//                 <li className="leading-relaxed mt-2 text-lg"><strong>Convenience:</strong> For out-of-town or international buyers, virtual staging provides a realistic preview of the property without the need for an in-person visit, making it easier for them to consider the property from afar.</li>
//               </ul>
//             </div>
//             <div className="flex-1">
//               <img src="https://www.meryashome.com/static/media/MH_28.502521cdce6bd7110221.jpg" alt="Customized virtual staging options for diverse buyer demographics" className="rounded-lg object-cover w-full h-64 md:h-96" />
//             </div>
//           </div>

//           {/* Section 2 with Center Image */}
//           <h2 className="text-2xl font-semibold text-gray-800 !my-10">2. Boosting Online Interest and Engagement</h2>
//           <p className="text-gray-600 leading-relaxed mt-2">
//           Properties that are virtually staged tend to attract significantly more views online than those that are empty or unfurnished. This heightened online presence can lead to more inquiries, better engagement, and ultimately, a quicker sale. Here’s how Meryas Home helps you stand out:              </p>
//           <div className="flex flex-col md:flex-row-reverse items-center gap-8">
            
//             <div className="flex-1">
             
//               <ul className=" list-inside ml-4 mt-4 space-y-2 text-gray-600 list-none">
//                 <li className="leading-relaxed mt-2 text-lg"><strong>Eye-Catching Listings:</strong> Meryas Home delivers beautifully staged images that grab the attention of potential buyers scrolling through online listings or social media feeds, helping your property shine in a crowded market.</li>
//                 <li className="leading-relaxed mt-2 text-lg"><strong>ncreased Click-Through Rates:</strong> Professionally staged homes get more interest, which translates to higher click-through rates and more views. This can improve a listing’s ranking on real estate platforms, bringing it to more buyers’ attention.</li>
//                 <li className="leading-relaxed mt-2 text-lg"><strong>Social Media Ready:</strong> Virtual staging by Meryas Home is crafted to look stunning on social media, where visual engagement drives reach and interest. This maximizes the property’s exposure and helps draw in a larger audience.</li>
//               </ul>
//             </div>
//             <div className="flex-1">
//               <img src="https://www.meryashome.com/static/media/MH_28.502521cdce6bd7110221.jpg" alt="Customized virtual staging options for diverse buyer demographics" className="rounded-lg object-cover w-full h-64 md:h-96" />
//             </div>
//           </div>


//           {/* Section 3 with Right Image */}
//           <h2 className="text-2xl font-semibold text-gray-800 !my-10 ">3. Reducing Time on the Market and Maximizing Sale Prices</h2>
//           <p className="text-gray-600 leading-relaxed mt-2">
//           One of the most appealing aspects of virtual staging is its ability to speed up sales and increase property value. Homes that are staged – even virtually – tend to sell faster and often attract higher offers. Here’s how Meryas Home helps you achieve these outcomes:</p>
//           <div className="flex flex-col md:flex-row items-center gap-8">
            
//             <div className="flex-1">
             
//               <ul className=" list-inside ml-4 mt-4 space-y-2 text-gray-600 list-none">
//                 <li className="leading-relaxed mt-2 text-lg"><strong>Speeding Up Sales:</strong> Meryas Home’s virtual staging makes properties look move-in ready and appealing, encouraging buyers to act sooner.</li>
//                 <li className="leading-relaxed mt-2 text-lg"><strong>Higher Sale Prices:</strong> Staged homes are perceived as more valuable, often leading to higher offers.</li>
//                 <li className="leading-relaxed mt-2 text-lg"><strong>Cost-Effective Solution:</strong> Unlike traditional staging, virtual staging by Meryas Home requires no physical furniture or setup costs, making it a more affordable option with equally powerful results.</li>
//               </ul>
//             </div>
//             <div className="flex-1">
//               <img src="https://www.meryashome.com/static/media/MH_28.502521cdce6bd7110221.jpg" alt="Customized virtual staging options for diverse buyer demographics" className="rounded-lg object-cover w-full h-64 md:h-96" />
//             </div>
//           </div>
         
//         </section>

//         {/* Conclusion Section */}
//         {/* <section className="mb-10">
//           <h2 className="text-2xl font-semibold text-gray-800">Conclusion</h2>
//           <p className="text-gray-600 leading-relaxed mt-2">
//             Virtual staging is truly a game-changer in real estate marketing, providing sellers with a powerful way to make their listings stand out, connect with buyers, and drive sales. At Meryas Home, we’re committed to helping our clients achieve remarkable results with beautifully staged, market-ready images that capture attention and inspire buyers.
//           </p>
//         </section> */}
//         {/* Conclusion Section */}
// <section className="mb-10">
//   <h2 className="text-2xl font-semibold text-gray-800 !my-10">Conclusion</h2>
//   <p className="text-gray-600 leading-relaxed mt-2">
//     Virtual staging has transformed real estate marketing, and Meryas Home is at the forefront of this innovation, offering a suite of services to elevate property listings.
//   </p>

//   {/* What Meryas Home Provides */}
//   <div className="mt-6">
//     <h3 className="text-2xl font-semibold text-gray-800 !my-10">What Meryas Home Provides</h3>
//     <p className="text-gray-600 leading-relaxed mt-2">
//       Meryas Home offers a variety of services to enhance property listings:
//       <ul className="list-disc list-inside mt-2">
//         <li className="leading-relaxed mt-2 text-lg"><strong>Virtual Staging:</strong> Digitally furnish and decorate properties.</li>
//         <li className="leading-relaxed mt-2 text-lg"><strong>3D Rendering for Renovations:</strong> Showcase potential upgrades or redesigns.</li>
//         <li className="leading-relaxed mt-2 text-lg"><strong>Virtual Tours:</strong> Allow buyers to explore properties remotely.</li>
//       </ul>
//     </p>
//   </div>

//   {/* Why Choose Virtual Staging with Meryas Home */}
//   <div className="mt-6">
//     <h3 className="text-2xl font-semibold text-gray-800 !my-10">Why Choose Virtual Staging with Meryas Home?</h3>
//     <p className="text-gray-600 leading-relaxed mt-2">
//       Meryas Home’s virtual staging services provide a cost-efficient alternative to traditional staging, with flexible design options customizable to any property style. Properties with virtual staging not only attract more views and engagement but often sell faster. For sellers looking to make a strong impression and maximize their property’s potential in the Montreal real estate market, Meryas Home offers an ideal solution.
//     </p>
//   </div>

//   {/* Get Started with Meryas Home */}
//   <div className="mt-6">
//     <h3 className="text-2xl font-semibold text-gray-800 !my-10">Get Started with Meryas Home</h3>
//     <p className="text-gray-600 leading-relaxed mt-2">
//       Ready to transform your property listing? <a href="https://www.meryashome.com/prices" className="text-blue-600 underline">Uploads your photos on Meryas Home</a> and experience the impact of professional virtual staging.
//     </p>
//   </div>
// </section>

//       </div>
//     // </div>
//   );
// }

// export default BlogDetail;
import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { blogData } from "../../data/Blogs";
import { blogDataFr } from "../../data/BlogsFr";
import { Helmet } from "react-helmet";
import { environment } from "../../envirement";
import blog1Banner from "../../assets/img/banner/2.png"
import blog2Banner from "../../assets/img/banner/3.png"
import blog3Banner from "../../assets/img/banner/4.png"
import blog4Banner from "../../assets/img/banner/5.png"
import blog5Banner from "../../assets/img/banner/6.png"
import blog6Banner from "../../assets/img/banner/7.png"
import blog7Banner from "../../assets/img/banner/8.png"
import blog8Banner from "../../assets/img/banner/9.png"
import blog9Banner from "../../assets/img/banner/1.png"
import vs1 from "../../assets/img/vs/1 - VS_Meryas.png"
import vs2 from "../../assets/img/vs/2 - VS_Meryas.png"
import vs3 from "../../assets/img/vs/3 - VS_Meryas.png"
import vs4 from "../../assets/img/vs/4 - VS_Meryas.png"
import vs5 from "../../assets/img/vs/5 - VS_Meryas.png"
import vs6 from "../../assets/img/vs/6 - VS_Meryas.png"
import vs7 from "../../assets/img/vs/7 - VS_Meryas.png"
import vs8 from "../../assets/img/vs/8 - VS_Meryas.png"
import vs9 from "../../assets/img/vs/11 - VS_Meryas.png"
import vs10 from "../../assets/img/vs/10 - VS_Meryas.png"
import vs11 from "../../assets/img/vs/12 - VS_Meryas.png"
import vs12 from "../../assets/img/vs/13 - VS_Meryas.png"
import vs13 from "../../assets/img/vs/14 - VS_Meryas.png"
import vs14 from "../../assets/img/vs/15 - VS_Meryas.png"
import vs15 from "../../assets/img/vs/16 - VS_Meryas.png"
import vs16 from "../../assets/img/vs/17 - VS_Meryas.png"
import vs17 from "../../assets/img/vs/18 - VS_Meryas.png"
import vs18 from "../../assets/img/vs/19 - VS_Meryas.png"
import vs19 from "../../assets/img/vs/20 - VS_Meryas.png"
import vs20 from "../../assets/img/vs/21 - VS_Meryas.png"
import vs21 from "../../assets/img/vs/22 - VS_Meryas.png"
import vs22 from "../../assets/img/vs/23 - VS_Meryas.png"
import vs23 from "../../assets/img/vs/24 - VS_Meryas.png"
import vs24 from "../../assets/img/vs/25 - VS_Meryas.png"
import vs25 from "../../assets/img/vs/26 - VS_Meryas.png"
import Footer from "../../components/Footer/Footer";
function BlogDetail() {
  const { id } = useParams(); // Get the blog ID from the URL
  const { i18n } = useTranslation(); // Get the current language from i18n
  console.log(i18n.language)

  // Choose the correct blog data based on the language
  const blogs = i18n.language === "fr" ? blogDataFr : blogData;

  // Find the blog using the ID from the URL
  const blog = blogs?.find(b => b.id == id);

  // If no blog is found, show a not-found message
  if (!blog) {
    return <p>Blog not found!</p>;
  }

  // Function to get the banner by ID
  const banners = [
    blog1Banner,
    blog2Banner,
    blog3Banner,
    blog4Banner,
    blog5Banner,
    blog6Banner,
    blog7Banner,
    blog8Banner,
    blog9Banner
  ];

  const listing = (id) => {
    return banners[id - 1] || null; // Subtract 1 to match array index
  };

  const blogImages = [
    // Blog 1 images
    [vs1, vs2, vs3, vs4, vs5],
    // Blog 2 images
    [vs6, vs7, vs8, vs9, vs10],
    // Blog 3 images
    [vs11, vs12, vs13, vs14, vs15],
    // Blog 4 images
    [vs16, vs17, vs18,vs19,vs20],
    [vs21],
    [vs22],
    [vs23],
    [vs24],
    [vs25],
  ];

  const getBlogImages = (blogId) => {
    return blogImages[blogId - 1] || []; // Adjust for zero-based index
  };

  return (
    <>
   
    <div className="mx-auto bg-white rounded-lg shadow-md p-8 px-[20%]">
      {/* Meta tags for SEO */}
      <Helmet>
  {/* Standard Meta Tags */}
  <title>{blog.title} | Meryas Home Virtual Staging Blog</title>
  <meta name="description" content={blog.description} />
  <meta name="keywords" content={blog.keywords} />
  {/* Open Graph Meta Tags for Social Sharing */}
  <meta property="og:title" content={`${blog.title} | Meryas Home Virtual Staging Blog`} />
  <meta property="og:description" content={blog.description} />
  <meta property="og:type" content="article" />
  <meta property="og:url" content={`https://www.meryashome.com/blogs/${blog.id}/${blog.title}`} />
  <meta property="og:image" content={blog.imageUrl || "https://www.meryashome.com/static/media/17%20-%20VS_Meryas.5329dc2047961a554379.png"} />
  <meta property="og:site_name" content="Meryas Home" />

  {/* Canonical Link */}
  <link rel="canonical" href={`https://www.meryashome.com/blogs/${blog.id}/${blog.title}`} />
</Helmet>

      {/* Intro Section */}
      <section className="mb-10">
        <h1 className="text-4xl font-bold text-gray-800 mt-[14vh] mb-10">{blog.title}</h1>
        <p className="text-gray-600 leading-relaxed">{blog.intro}</p>
      </section>

      {/* Image for Introduction */}
      <div className="flex justify-center mb-8">
        <img
          src={listing(blog.id)}
          alt={blog.imageAlt}
          className="rounded-lg object-cover w-full h-64 md:h-80"
        />
      </div>

      {/* Content Sections */}
      {blog.sections.map((section, index) => (
        <section key={index} className="mb-10 space-y-8 group">
          <h2 className="text-2xl font-semibold text-gray-800 !my-10">{section.title}</h2>
          <p className="text-gray-600 leading-relaxed mt-2">{section.content}</p>

          <div className="flex flex-col group-odd:md:flex-row group-even:md:flex-row-reverse items-center gap-8">
            <div className="flex-1">
              <ul className="list-inside ml-4 mt-4 space-y-2 text-gray-600 list-none">
                {section.points.map((point, index) => (
                  <li key={index} className="leading-relaxed mt-2 text-lg">
                    <strong>{point.title}:</strong> {point.description}
                  </li>
                ))}
              </ul>
            </div>

            {/* Image for each section */}
            {section.imageUrl && (
              <div className="flex-1">
                <img
                  src={getBlogImages(blog.id)[index]} // Fetch the corresponding image for this section
                  alt={`Blog ${blog.id} Section ${index + 1}`}
                  className="rounded-lg object-cover w-full h-64 md:h-80"
                />
              </div>
            )}
          </div>
        </section>
      ))}

      {/* Conclusion Section */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold text-gray-800 !my-10">Conclusion</h2>
        <p className="text-gray-600 leading-relaxed mt-2">{blog.conclusion}</p>
      </section>

      {/* Additional Information Sections */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold text-gray-800 !my-10">What Meryas Home Provides</h2>
        <div className="space-y-6">
          {blog?.additionalInfo?.whatMeryasHomeProvides?.map((item, index) => (
            <div key={index} className="space-y-2">
              <li className="leading-relaxed mt-2 text-lg">
                <strong>{item?.title}:</strong> {item?.description}
              </li>
            </div>
          ))}
        </div>
      </section>

      {/* Get Started Section */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold text-gray-800 !my-10">{blog?.additionalInfo?.getStarted?.title}</h2>
        <p className="text-gray-600">{blog.additionalInfo.getStarted?.description}</p>
      </section>

      {/* Why Choose Virtual Staging */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold text-gray-800 !my-10">{blog.additionalInfo.whyChooseVirtualStaging?.title}</h2>
        <Link to="/prices">
          <p className="* text-[#ecb365] cursor-pointer">
            {blog.additionalInfo.whyChooseVirtualStaging?.description}
          </p>
        </Link>
      </section>
    </div>
    <Footer/>
    </>
  );
}

export default BlogDetail;
