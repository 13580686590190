import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import ServiceDetails from "../../../components/HomeComponents/Services/ServiceDetails/ServiceDetails";
import { PlaceOrder } from "../../../components/HomeComponents/PlaceOrderComponent/PlaceOrder";
import Testimonials from "../../../components/Testimonials/Testimonials";
import Footer from "../../../components/Footer/Footer";
import Partners from "../../../components/HomeComponents/Partners/Partners";
import { Helmet } from "react-helmet-async";
import WhyUs from "../../../components/HomeComponents/WhyUs/WhyUs";
import Steps from "../../Steps";
import StepSignup from "../../../components/SignUpSteps";

const countries = "United States, Canada, Europe, United Kingdom, New Zealand, Australia, Other";
const serviceMetadata = {
  "virtual-staging": {
    title: "Virtual Staging | Meryas Home",
    description: "Boost property sales with Meryas Home's virtual staging service. Transform empty rooms into beautifully furnished spaces for buyers in the United States, Canada, Europe, the United Kingdom, New Zealand, Australia, and beyond.",
    keywords: "virtual staging, digital staging, real estate virtual staging, home staging, property marketing, online staging, United States, Canada, Europe, United Kingdom, New Zealand, Australia",
    img:"https://www.meryashome.com/static/media/2%20-%20VS_Meryas.d4376e44a8ab27e21522.png",
    url:"https://www.meryashome.com/service/virtual-staging"
  },
  "virtual-renovation": {
    title: "Virtual Renovation | Meryas Home",
    description: "Transform outdated and on construction spaces into modern designs with Meryas Home's virtual renovation services. Ideal for property listings and interior redesigns in the United States, Canada, Europe, the United Kingdom, New Zealand, Australia, and more.",
    keywords: "virtual renovation, digital renovation, home renovation, interior design update, real estate remodeling, virtual property transformation, United States, Canada, Europe, United Kingdom, New Zealand, Australia",
    img:"https://www.meryashome.com/static/media/RV%201_Meryas.da2e7695408c2dadacca.png",
    url:"https://www.meryashome.com/service/virtual-renovation"
  },
  "3D-rendering(interior)": {
    title: "3D Interior Rendering for Real Estate | Meryas Home",
    description: "Create stunning and realistic 3D interior renderings with Meryas Home, showcasing your property designs in the United States, Canada, Europe, the United Kingdom, New Zealand, and Australia. Perfect for real estate and design projects.",
    keywords: "3D interior rendering, interior design, 3D visualization, property marketing, real estate interior rendering, digital design, United States, Canada, Europe, United Kingdom, New Zealand, Australia",
    img:"	https://www.meryashome.com/static/media/3D%201_Meryas.5bbe3f6f14eb9266416c.png",
    url:"https://www.meryashome.com/service/3D-rendering(interior)"
  },
  "3D-rendering(exterior)": {
    title: "3D Exterior Rendering Services | Meryas Home",
    description: "Showcase properties with high-quality 3D exterior renderings from Meryas Home. Bring your architectural visions to life for clients in the United States, Canada, Europe, the United Kingdom, New Zealand, and Australia.",
    keywords: "3D exterior rendering, architectural visualization, outdoor design, real estate 3D rendering, exterior home design, property visualization, United States, Canada, Europe, United Kingdom, New Zealand, Australia",
    img:"https://www.meryashome.com/static/media/3D%203_Meryas.41f263d1092fcdf05595.png",
    url:"https://www.meryashome.com/service/3D-rendering(exterior)"

  },
  "image-Enhancement": {
    title: "Real Estate Image Enhancement | Meryas Home",
    description: "Elevate your property listings with Meryas Home's professional image enhancement services. Perfect for real estate photography across the United States, Canada, Europe, the United Kingdom, New Zealand, Australia, and more.",
    keywords: "image enhancement, photo editing, real estate photo enhancement, property photography, high-quality images, United States, Canada, Europe, United Kingdom, New Zealand, Australia",
    url:"https://www.meryashome.com/service/image-Enhancement"

  },
  "item-removal": {
    title: "Item Removal for Property Photos | Meryas Home",
    description: "Clean up property photos with Meryas Home’s item removal service. Remove unwanted elements for a polished, professional look in the United States, Canada, Europe, the United Kingdom, New Zealand, Australia, and other regions.",
    keywords: "item removal, photo cleanup, real estate photo editing, clutter removal, property photo retouching, United States, Canada, Europe, United Kingdom, New Zealand, Australia",
    img:"https://www.meryashome.com/static/media/10.a7d5d7dbef4b0ad2f6a2.png",
    url:"https://www.meryashome.com/service/item-removal"

  },
  "2d-textured-plan": {
    title: "2D Textured Plan Design | Meryas Home",
    description: "Visualize properties with Meryas Home's detailed 2D Textured plans, ideal for real estate presentations in the United States, Canada, Europe, the United Kingdom, New Zealand, Australia, and other global markets.",
    keywords: "2D Textured plan, property layout, real estate floor plan, floor plan design, property visualization, United States, Canada, Europe, United Kingdom, New Zealand, Australia",
    img:"https://www.meryashome.com/static/media/WebB-A17-15x6.f184949490a1757401a1.png",
    url:"https://www.meryashome.com/service/2d-textured-plan"

  },
  "day-to-dusk": {
    title: "Day to Dusk Photo Transformation | Meryas Home",
    description: "Convert daytime property images to stunning dusk visuals with Meryas Home’s day-to-dusk photo editing. Enhance your listings for clients in the United States, Canada, Europe, the United Kingdom, New Zealand, Australia, and beyond.",
    keywords: "day to dusk transformation, evening photo editing, real estate photography, dusk effect, property image enhancement, United States, Canada, Europe, United Kingdom, New Zealand, Australia",
    img:"https://www.meryashome.com/static/media/WebB-A1-15x6.a586f07fb878de232aa4.png",
    url:"https://www.meryashome.com/service/day-to-dusk"

  },
  "photo-modification": {
    title: "Photo Modification for Real Estate | Meryas Home",
    description: "Enhance your property images with professional photo modification services from Meryas Home. Add features, change materials, and improve visuals for properties in the United States, Canada, Europe, the United Kingdom, New Zealand, Australia, and more.",
    keywords: "photo modification, real estate photo editing, property enhancements, image alterations, real estate photography, United States, Canada, Europe, United Kingdom, New Zealand, Australia",
    img:"https://www.meryashome.com/static/media/WebB-A14-15x6.f07b69b59d0a8d645c3e.png",
    url:"https://www.meryashome.com/service/photo-modification"

  }
};

const ServiceDetailsPage = () => {
  const { serviceId } = useParams();
  console.log(serviceId)
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get the metadata for the current service
  const serviceMeta = serviceMetadata[serviceId] || {
    title: "Service Not Found",
    description: "The requested service is not available.",
    keywords: `service, not found, ${countries}`,
    url:"ghj"
  };

  return (
    <>
      <Helmet>
        <title>{serviceMeta.title}</title>
        <meta name="description" content={serviceMeta.description} />
        <meta name="keywords" content={serviceMeta.keywords} />
          {/* Open Graph Meta Tags for Social Sharing */}
  <meta property="og:title" content={serviceMeta.title} />
  <meta property="og:description" content={serviceMeta.description} />
  <meta property="og:type" content="website" />
  <meta property="og:url" content={serviceMeta.url} />
  <meta property="og:image" content={serviceMeta.img} />
  <meta property="og:site_name" content="Meryas Home" />
    {/* Twitter Meta Tags for Social Sharing */}
    <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content={serviceMeta.title} />
  <meta
    name="twitter:description"
    content={serviceMeta.description}
  />
  <meta name="twitter:image" content={serviceMeta.img} />
  <meta name="twitter:site" content="@meryas_home" />
  {/* Canonical Link */}
  <link rel="canonical" href={serviceMeta.url} />
      </Helmet>
      <ServiceDetails />
      <div className="go-to-services">
        <p>{t("home.our-services.text")}</p>
        <Link to={`/${serviceId}`}>
          <button>{t("home.our-services.btn")}</button>
        </Link>
      </div>
      <WhyUs/>
    <Steps/>
    <StepSignup/>
    <Partners />
    <PlaceOrder />
    <Testimonials />
      <Footer />
    </>
  );
};

export default ServiceDetailsPage;
